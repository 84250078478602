import React from "react";
import {
  Div,
  Col,
  Row,
  Text,
} from "atomize";
import Cryptocurrencies from "./components/Cryptocurrencies";
import { listCryptocurrencies, queryVotesByVotesByDayTypeIndex } from './graphql/queries'
import { Amplify, API } from 'aws-amplify';
import awsconfig from './aws-exports';
import ReactGA from 'react-ga4';
import uuid from 'react-uuid';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

ReactGA.initialize([
  {
    trackingId: "G-2GZC44L4WE",
  },
]);

Amplify.configure(awsconfig);

class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    // TODO: Export as function
    let newDate = new Date()
    let date = newDate.getUTCDate();
    let month = newDate.getUTCMonth();
    let year = newDate.getUTCFullYear();

    // Create new cookie
    if (cookies.get('hodlwhat') === undefined) {
      cookies.set('hodlwhat', uuid(), { path: '/' });
    }

    this.state = { 
      cryptocurrencies: [],
      periodType: `${year}-${month+1}-${date}#cryptocurrency`,
      sessionId: cookies.get('hodlwhat'),
    };
  }

  componentDidMount() {
    this.listCryptocurrencies();
  }

  async listCryptocurrencies() {
    const votesResponse = await API.graphql({
      query: queryVotesByVotesByDayTypeIndex,
      variables: {
        periodType: `${this.state.periodType}#twitter`,
      },
    })
    
    // Remaining items to query
    let remaining = 50 - votesResponse.data.queryVotesByVotesByDayTypeIndex.items.length

    const response = await API.graphql({
      query: listCryptocurrencies,
      variables: {
        limit: remaining,
      },
    })

    let items = [...votesResponse.data.queryVotesByVotesByDayTypeIndex.items]

    response.data.listCryptocurrencies.items.forEach((cryptocurrency) => {
      const found = items.find(item => item.uid === cryptocurrency.uid)

      // Add only if it does not already exists
      if (!found) {
        items.push({ 
          periodType: this.state.periodType,
          uid: cryptocurrency.uid,
          votes: 0,
          cryptocurrency: {
            name: cryptocurrency.name,
            symbol: cryptocurrency.symbol,
          }
        })
      }
    })
  
    this.setState({ 
      cryptocurrencies: items,
    });
  }

  render() {
    return (
      <Div p="1rem" d="flex" flexDir="column">
        {/* <Div p="1rem" d="flex" align="flex-end" flexDir="column">
          <InstructionsModal />
        </Div> */}
        <Div p="1rem" d="flex" align="center" flexDir="column">
          <Text textColor="black" textSize="heading">Welcome to hodlwhat.com</Text>
          <Text textColor="black" textSize="body">First social sharing where you can share what cryptocurrencies you are HODLing by tweeting.</Text>
        </Div>
        <Div p="1rem" d="flex" align="center" flexDir="column">
          <Text textColor="black" textSize="heading">What is HODL?</Text>
          <Text textColor="black" textSize="body">HODL is short for "hold on for dear life", a popular term among cryptocurrency investors. HODL is one way to cope with market volatility.</Text>
          <Text textColor="black" textSize="body">This website aims to guide and share cryptocurrencies everyone else are HODLing. Help others by sharing what you are HODLing. NFA.</Text>
        </Div>
        <Div p="1rem" d="flex" align="center" flexDir="column">
          <Text textColor="black" textSize="heading">How to share?</Text>
          <Text textColor="black" textSize="body">Click on the green button on each corresponding cryptocurrency you are HODLing. This opens a new tab with a prefilled tweet.</Text>
          <Text textColor="black" textSize="body">Alternatively, you can tweet "hodl #symbol @hodlwhat" eg. hodl #btc @hodlwhat</Text>
        </Div>
        <Div p="1rem" d="flex" flexDir="column">
          <Row justify="center">
            <Col size={{ xs: 12, lg: 6 }}>
              <Cryptocurrencies sessionId={this.state.sessionId} periodType={this.state.periodType} items={this.state.cryptocurrencies} />
            </Col>
          </Row>
        </Div>
      </Div>
    );
  }
}

export default withCookies(App);
