/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCryptocurrency = /* GraphQL */ `
  query GetCryptocurrency($uid: String!) {
    getCryptocurrency(uid: $uid) {
      uid
      name
      symbol
      votes
    }
  }
`;
export const listCryptocurrencies = /* GraphQL */ `
  query ListCryptocurrencies(
    $filter: TableCryptocurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCryptocurrencies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uid
        name
        symbol
        votes
      }
      nextToken
    }
  }
`;
export const getVotes = /* GraphQL */ `
  query GetVotes($periodType: String!, $uid: String!) {
    getVotes(periodType: $periodType, uid: $uid) {
      periodType
      uid
      votes
      cryptocurrency {
        uid
        name
        symbol
        votes
      }
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: TableVotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        periodType
        uid
        votes
        cryptocurrency {
          uid
          name
          symbol
          votes
        }
      }
      nextToken
    }
  }
`;
export const queryVotesByVotesByDayTypeIndex = /* GraphQL */ `
  query QueryVotesByVotesByDayTypeIndex(
    $periodType: String!
    $first: Int
    $after: String
  ) {
    queryVotesByVotesByDayTypeIndex(
      periodType: $periodType
      first: $first
      after: $after
    ) {
      items {
        periodType
        uid
        votes
        cryptocurrency {
          uid
          name
          symbol
          votes
        }
      }
      nextToken
    }
  }
`;
