import React from "react";
import {
  Div,
  Text,
  Button,
  Row,
  Col,
  Anchor,
} from "atomize";
import { API } from 'aws-amplify';
import { upvoteCryptocurrency, createVotes } from './../graphql/mutations'

async function upvote(sessionId, periodType, uid) {
  const createVotesInput = {
    periodType: `${periodType}#user`,
    uid: `${sessionId}#${uid}`,
  };

  const createResponse = await API.graphql({ query: createVotes, variables: { input: createVotesInput }})
    .catch(response => {
      if (response.errors.length === 1 && response.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException") {
        throw Error("ConditionalCheckFailedException");
      }
    });
  
  if (createResponse.errors) {
    return 
  }

  const upvoteInput = {
    periodType: periodType,
    uid: uid,
  };

  const upvoteResponse = await API.graphql({ query: upvoteCryptocurrency, variables: { input: upvoteInput }})

  return upvoteResponse.data.upvoteCryptocurrency
}

class Cryptocurrencies extends React.Component {
  constructor(props) {  
    super(props);

    this.state = { 
      items: [],
    };
  }

  link(item) {
    return `https://twitter.com/intent/tweet?text=hodl%20%23${item.cryptocurrency.symbol}%20%40hodlwhat`;
  }

  render() {
    const items = this.props.items;
    const listItems = items
      .sort((a, b) => a.votes > b.votes ? -1 : 1)
      .map((item) => (
        <Div key={item.uid} p="1rem" hoverBg="gray200" cursor="pointer">
          <Row justify="center">
            <Col size={{ xs: 8, lg: 10 }}>
              <Div d="flex" flexDir="column">
                <Div h="100%">
                  <Text textSize="subheader">{item.cryptocurrency.name}</Text>
                  <Text textSize="subheader" textColor="gray900">{item.cryptocurrency.symbol}</Text>
                </Div>
              </Div>
            </Col>

            <Col size={{ xs: "auto" }}>
              <Anchor 
                className="twitter-share-button" 
                href={this.link(item)}
              >
                <Button
                  h="4rem"
                  w="4rem"
                  bg="white"
                  border="1px solid"
                  borderColor="success500"
                  hoverBorderColor="success700"
                >
                  <Row>
                    <Col size="12">
                      <Text textColor="success700" textSize="caption">HODL</Text>
                    </Col>
                    <Col size="12">
                      <Text textColor="black" textSize="caption">{item.votes}</Text>
                    </Col>
                  </Row>
                </Button>
              </Anchor>
            </Col>
          </Row>
        </Div>
      ));

    return (
      <>
        <Div p="1rem" className="cryptocurrencies">
          <Div p="1rem">
            <Text textColor="black" textSize="heading">HODL what today?</Text>
          </Div>
          {listItems}
        </Div>
      </>
    );
  }
}

export default Cryptocurrencies;